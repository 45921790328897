.watermark-container {
    height: 68px;
    max-width: 56%;
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 14px;
    z-index: 30;
    .tooltip {
        position: absolute;
        bottom: 36px;
        right: 0;
        width: 100%;
        background-color: rgba(0,0,0,.75);
        border-radius: 8px;
        padding: 5px 10px;
    }
    p {
        font-size: 16px;
        text-align: center;
        color: $color-white;
        margin: 0;
    }
    .btn-remove-logo {
        height: 30px;
        width: 190px;
        position: absolute;
        right: -14px;
        bottom: 0;
        cursor: pointer;
        margin: 0;
    }
    .btn-logo-on {
        font-family: $font-secondary;
        font-size: 12px;
        line-height: 1.17;
        text-transform: uppercase;
        color: $color-secondary;
        position: absolute;
        bottom: -27px;
        right: -8px;
        background-color: transparent;
        padding: 20px 10px 5px;
    }
}
