.logo {
    font-family: $font-logo;
    font-size: 32px;
    line-height: 1;
    color: $color-primary;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin-right: 24px;
    svg {
        height: 13px;
        margin-top: -1px;
        margin-right: 6px;
    }
}

/*main page*/
.main-page {
    .container {
        position: relative;
    }
    .btn-upload-foto {
        display: none;
    }
    .line-main {
        position: absolute;
        top: -56px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-width: 1200px;
        fill: none;
        z-index: 5;
    }
}

.btn-upload-foto-desktop {
    display: none;
}

.main-page,
.collage-page {
    padding-top: 56px;
}

header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 15;
    margin-bottom: 40px;
}

.header-main-content {
    display: flex;
    align-items: center;
}

.header-other-content {
    display: flex;
    align-items: center;
}

.app-links-container {
    position: relative;
    margin-left: 56px;
}

.app-links {
    border-radius: 20px;
    box-shadow: 0 0 60px 0 rgba(10,19,101,.6);
    background-color: $color-secondary;
    position: absolute;
    width: 295px;
    top: 88px;
    right: 0;
    overflow: hidden;
    display: none;
    z-index: 50;
    &.open {
        display: block;
    }
}

.main-page-content {
    position: relative;
    z-index: 10;
    // padding-left: 96px;
    margin-bottom: 160px;
    .wr-slider-images {
        position: relative;
        width: calc(100% + 40px);
        left: -20px;
        .slide {
            display: block;
            padding: 0;
            img {
                width: 100%;
            }
        }
        .slick-dots {
            bottom: 19px;
        }
    }
}

.main-page-title {
    font-family: $font-secondary;
    font-size: 48px;
    line-height: 1.5;
    text-align: left;
    margin-bottom: 16px;
    span {
        border-radius: 8px;
        background-image: linear-gradient(to left, #a4e6a7, rgba(164, 230, 167, 0));
        padding-right: 4px;
    }
    &:last-of-type {
        margin-bottom: 0;
    }
}

.main-page-subtitle {
    line-height: 1.5;
    text-align: left;
    margin-top: 32px;
}

.wr-slider-images {
    width: 100%;
    .slick-list,
    .slick-slider {
        position: relative;
    }
    .slick-dots {
        bottom: 3px;
        li {
            width: 6px;
            height: 6px;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            vertical-align: middle;
            margin: 0 4px;
        }
        button {
            background-color: $color-white;
            border-radius: 3px;
            width: 100%;
            height: 100%;
        }
        .slick-active {
            width: 42px;
            button {
                background-color: $color-white;
            }
        }
    }
    .slick-active {
        .slide {
            border-color: #ffdd0d;
        }
    }
    .slide {
        display: flex;
        justify-content: center;
        border-radius: 24px;
        box-sizing: border-box;
        overflow: hidden;
        padding: 0 20px;
        margin: 0 auto;
        img {
            border-radius: 32px;
            width: 312px;
        }
    }
}

.wr-slider-logos {
    width: 100%;
    position: static;
    box-sizing: border-box;
    // padding-left: 96px;
    margin-bottom: 108px;
    .slider {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .slick-track {
        display: flex;
        align-items: center;
    }
    .slick-dots {
        bottom: -20px;
        li {
            font-size: 0;
            width: 6px;
            height: 6px;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            vertical-align: middle;
            margin: 0 4px;
        }
        button {
            font-size: 0;
            background-color: $color-grey;
            border-radius: 3px;
            width: 100%;
            height: 100%;
        }
        .slick-active {
            width: 42px;
            button {
                background-color: $color-grey;
            }
        }
    }
    a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 154px;
        margin-right: 39px;
        margin-bottom: 53px;
    }
    img {
        width: 100%;
    }
}

.update-info {
    width: 65%;
    align-self: flex-end;
    border-radius: 32px;
    border: dotted 2px #ebe5ff;
    position: relative;
    // left: -96px;
    padding: 16px 25px;
    padding-left: 96px;
    margin-bottom: 24px;
    margin-top: 24px;
    a {
        display: block;
    }
    span {
        // font-size: 14px;
        line-height: 1.5;
        display: block;
        text-align: left;
    }
    &-title {
        margin-bottom: 4px;
    }
    &-date {
        font-family: $font-secondary;
        color: $color-red;
        text-transform: uppercase;
    }
}

.btns-market-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    margin-bottom: 80px;
}

.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding-left: 96px;
    padding-bottom: 60px;
}

.posts-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 56px;
    a {
        width: 100%;
        display: block;
    }
}

.instagram {
    // padding-left: 96px;
    // padding-right: 96px;
    margin-bottom: 40px;
    .post {
        width: 32%;
        overflow: hidden;
        margin-bottom: 40px;
    }
    .post-image {
        position: relative;
        margin-bottom: 8px;
        &:before {
            content: "";
            display: block;
            padding-top: 100%;
        }
        a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        img {
            border-radius: 24px;
            width: 100%;
        }
    }
    .user-post {
        margin-bottom: 20px;
    }
    .user-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .user-name {
        display: flex;
        align-items: center;
        max-width: 80%;
        img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            margin-right: 8px;
        }
        p {
            font-family: $font-secondary;
            font-size: 16px;
            line-height: 1.5;
            color: $color-primary;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .user-likes {
        display: flex;
        align-items: center;
        p {
            font-family: $font-secondary;
            font-size: 16px;
            line-height: 1.5;
            color: $color-primary;
        }
        span {
            font-family: $font-primary;
        }
        svg {
            width: 24px;
            height: 24px;
            margin-left: 8px;
        }
    }
}

.instagram-page {
    padding-top: 24px;
    padding-bottom: 50px;
    .wr-slider-images {
        margin-bottom: 8px;
        .slide {
            padding: 20px 20px 0;
            img {
                width: 100%;
            }
        }
    }
    .user-post {
        margin-bottom: 20px;
    }
    .user-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .user-name {
        display: flex;
        align-items: center;
        max-width: 80%;
        img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            margin-right: 8px;
        }
        p {
            font-family: $font-secondary;
            font-size: 16px;
            line-height: 1.5;
            color: $color-primary;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .user-likes {
        p {
            font-family: $font-secondary;
            font-size: 16px;
            line-height: 1.5;
            color: $color-primary;
        }
        span {
            font-family: $font-primary;
        }
    }
    .instagram {
        .main-page-title {
            font-size: 22px;
        }
    }
}

.title-contacts-form {
    font-size: 18px;
    text-align: left;
    line-height: 1.5;
    // padding-left: 96px;
    // padding-right: 96px;
    margin-bottom: 40px;
    a {
        color: $color-secondary;
    }
}

/*collage-page*/
.collage-page {
    height: 100vh;
    box-sizing: border-box;
    header {
        margin-bottom: 9vh;
    }
    .creative-holder {
        position: relative;
        max-width: 450px;
        width: 100%;
        margin: 0 auto 5vh;
        .loader-roller-wrapper {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            z-index: 10;
        }

        &.creative-holder--narrow {
            max-width: 320px;
        }
    }
    .imageview-host, 
    video {
        position: relative;
        border-radius: 24px;
        overflow: hidden;
        z-index: 20;
    }
    .imageview-holder, 
    .imageview-image {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        animation: imageview-show .32s;
    }
    .btn-upload-foto {
        font-size: 10px;
        color: $color-secondary;
        letter-spacing: 0.4px;
        line-height: 1.4;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: auto;
        height: auto;
        border-radius: 0;
        max-width: 30%;
        background-color: transparent;
        margin: 0 5%;
    }
    .btns-container {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        position: relative;
        z-index: 10;
        padding-top: 2vh;
        padding-bottom: 2vh;

        .btn-spacer {
            max-width: 30%;
            width: 100%;
        }
    }
    .icon-container {
        width: 64px;
        height: 64px;
        box-shadow: 0 0 15px 0 rgba(148, 12, 68, 0.4);
        background-color: $color-secondary;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;
        svg {
            fill: #fff;
            width: 24px;
            height: 24px;
            flex-shrink: 0;
        }
    }
}

.imageview-host {
    background-color: rgba(0,0,0,.1);
}

.creative-holder-placeholder {
    padding-top: 100%;
}

.wait-video {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 24px;
    overflow: hidden;
    z-index: 5;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: blur(7px);
    }
}

.result-line-mob {
    fill: none;
    position: absolute;
    left: -20px;
    top: -180px;
    width: calc(100% + 40px);
    z-index: -5;
}

@keyframes imageview-show {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
  
.tabs-container {
    display: flex;
    position: relative;
    left: -20px;
    width: calc(100% + 40px);
    overflow: auto;
    box-sizing: border-box;
    padding-left: 20px;
    padding-bottom: 10px;
    margin: 0 auto 2px;
    button {
        position: relative;
        width: 54px;
        height: 54px;
        border-radius: 12px;
        overflow: hidden;
        border: 3px solid transparent;
        margin-right: 5px;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;
        box-sizing: border-box;
        flex-shrink: 0;
    }
   .active-tab {
        border-color: #7aafff;
    }
    img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
    }
}

.collage__tab_love {
    background-image: url("/assets/img/tabs/tab-icon-love.png");
    background-color: #f0679f;
    background-size: 36px;
    background-position: center;
}

.btn-back-classic {
    font-family: 'Poppins-SemiBold', sans-serif;
    font-size: 16px;
    letter-spacing: 0.64px;
    text-align: left;
    text-transform: uppercase;
    color: #7676c0;
    background-color: transparent;
    display: flex;
    align-items: center;
    padding-left: 20px;
    width: 200px;
    height: 32px;
    border-radius: 16px;
    margin: 40px auto 0;
    p {
        margin-top: 2px;
    }
    span {
        font-family: 'Poppins', sans-serif;
    }
    svg {
        fill: #7676c0;
        height: 14px;
        margin-right: 8px;
    }
}

.loader-video {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 10;
}

.loader-video .item-loader {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    animation: item-loader .9s linear infinite;
    margin: 0 6px;
}

.loader-video .first-item-loader {
    background-color: #FFFFFF;
}

.loader-video .second-item-loader {
    background-color: #7676C0;
    animation: item-loader .9s linear infinite;
    animation-delay: .3s;
}

.loader-video .third-item-loader {
    background-color: #F0679F;
    animation: item-loader .9s linear infinite;
    animation-delay: .6s;
}


@keyframes item-loader {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.8, 1.8);
    }
}

.choose-page {
    padding-top: 16px;
    h2 {
        font-family: $font-secondary;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 1.13;
        text-align: center;
        margin-bottom: 5px;
    }
    p {
        line-height: 1.38;
        text-align: center;
        width: 100%;
    }
}
.choose-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 440px;
    margin: 0 auto;
}
.choose-item-container {
    width: 33%;
    margin-bottom: 1px;
}
.choose-item {
    position: relative;
    padding-top: 100%;
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
    }
}

.tab-custom {
    width: 100%;
    position: relative;
    margin-bottom: 85px;
    &.container {
        padding-top: 0;
    }
    h3 {
        font-family: $font-logo, "Helvetica";
        font-size: 30px;
        line-height: 1.16;
        text-align: center;
        margin-bottom: 14px;
    }
    p {
        font-size: 16px;
        text-align: center;
        line-height: 1.38;
        margin-bottom: 28px;
    }
    button {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        width: 260px;
        height: 48px;
        border-radius: 16px;
        display: block;
        background-color: #7676c0;
        margin: 0 auto;
    }
}

.tab-lab-content {
    position: absolute;
    bottom: -80px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 550px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 30px;
}

.tab-lab {
    h3 {
        color: #e434a5;
    }
    button {
        background-color: #e434a5;
    }
}

.bg-image-container {
    width: 100%;
    position: relative;
    &:before {
        content: "";
        display: block;
        padding-top: 100%;
    }
    img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.modal { 
    padding: 40px 24px;
    border-radius: 24px;
    box-shadow: 0 8px 60px 0 rgba(43,43,97, .6);
    background-color: #ffffff;
    box-sizing: border-box;
    max-width: 90%;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    &-text-processing {
        color: $color-grey-dark;
    }
    &-container {
        background-color: rgba(76, 76, 138, 0.86);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        &.snapchat {
            background-color: $color-yellow;
            h3 {
                font-family: $font-secondary;
                font-size: 28px;
                line-height: 1.43;
                text-align: center;
                margin-bottom: 24px;
            }
            p {
                margin-bottom: 36px;
            }
            .btn-share-snapchat {
                width: 200px;
                height: 48px;
                border-radius: 72px;
                background-color: #ffdd0d;
                font-weight: bold;
                text-transform: uppercase;
                display: block;
                margin: 0 auto;
            }
            .btn-back {
                font-family: $font-secondary;
                font-size: 16px;
                line-height: 1;
                text-align: left;
                color: $color-grey-dark;
                display: block;
                height: auto;
                width: auto;
                position: static;
                box-shadow: none;
                background-color: transparent;
                padding: 0;
                margin: 0;
                svg {
                    width: 16px;
                    height: 16px;
                    fill: $color-grey-dark;
                    margin-right: 8px;
                }
                span {
                    margin-top: 2px;
                }
            }
            .check-foto-container {
                display: flex;
                justify-content: space-between;
                margin-bottom: 40px;
            }
            .check-foto {
                width: 48%;
                border-radius: 16px;
                overflow: hidden;
                margin: 0;
                position: relative;
                background-color: grey;
                img {
                    height: 100%;
                    object-fit: cover;
                    display: block;
                    width: 100%;
                    position: absolute;
                    top: 0; 
                    left: 0; 
                    bottom: 0; 
                    right: 0;
                    z-index: 2;
                }
                &:before {
                    content: "";
                    display: block;
                    width: 100%;
                    padding-top: 100%;
                }
            }
        }
    }
    h3 {
        font-family: $font-secondary;
        font-size: 28px;
        line-height: 1.43;
        text-align: center;
        margin-bottom: 24px;
    }
    p {
        font-size: 16px;
        line-height: 1.5;
        text-align: center;
        margin-bottom: 16px;
    }
    
    .btn-cancel {
        font-family: $font-secondary;
        line-height: 1;
        letter-spacing: 0.64px;
        text-align: center;
        color: $color-grey-dark;
        text-transform: uppercase;
        max-width: initial;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 18px auto 0;
        svg {
            width: 14px;
            height: 14px;
            fill: $color-grey-dark;
            margin-right: 8px;
        }
        span {
            margin-top: 2px;
        }
    }
    .btns-container_share {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
    }
    .btn-share {
        width: 47px;
        height: 47px;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        border-radius: 50%;
    }
    
    .btn-share-insta {
        background-image: linear-gradient(45deg, #ffdd55 7%, #ff543e 50%, #c837ab 93%); 
        svg {
            display: block;
            height: 24px;
            width: 24px;
            margin: 0 auto;
        }
    }
    .btn-share-snap {
        background-color: #ffdd0d;
        svg {
            display: block;
            height: 24px;
            width: 25px;
            margin: 0 auto;
        }
    }
    .btn-share-fb {
        background-color: #1976d2;
        svg {
            display: block;
            height: 24px;
            width: 13px;
            margin: 0 auto;
        }
    }
    .btn-upload-foto {
        margin: 0;
        max-width: 96px;
        &.download {
            font-family: $font-secondary;
            font-size: 16px;
            line-height: 1;
            max-width: 100%;
            height: auto;
            display: flex;
            align-items: center;;
            letter-spacing: 0.64px;
            text-align: left;
            color: #2d3134;
            text-transform: uppercase;
            background-color: transparent;
            margin: 24px auto 0;
            svg {
                width: 24px;
                height: 24px;
                fill: #000;
                margin-right: 8px;
            }
        }
    }
    .btn-rate, 
    .btn-skip {
        font-family: $font-secondary;
        font-size: 16px;
        line-height: 1;
        letter-spacing: 0.64px;
        text-align: center;
        text-transform: uppercase;
        width: 180px;
        height: 56px;
        border-radius: 16px;
        background-color: $color-secondary;
        display: block;
        margin: 12px auto 0;
    }
    .btn-rate {
        color: $color-white;
        background-color: $color-secondary;
    }
    .btn-skip {
        color: $color-grey-dark;
        background-color: transparent;
    }
}

/*error-page*/
.error-page {
    height: 100Vh;
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 94vh;
    }
    p {
        font-size: 15px;
        text-align: center;
        margin-bottom: 20px;
    }
    svg {
        max-width: 264px;
        margin-bottom: 5.5vh;
    }
    h3 {
        font-weight: bold;
        margin-bottom: 16px;
    }
}

.error-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1.38;
}

.-show-popup {
    overflow: hidden;
}

.modal-container-body-select {
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-color: rgba(45, 49, 52, 0.7);
    .modal {
        padding: 72px 72px 62px 72px;
    }
    .btns-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 30px;
    }
    .btn-select-style {
        color: #fff;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 49%;
        height: 80px;
        flex-grow: 0;
        flex-shrink: 0;
        border-radius: 20px;
        padding-top: 4px;
    }
    .btn-select-head {
        background-color: #f6704d;
    }
    .btn-select-body {
        background-color: #4d5ef6;
    }
    .btn-cancel {
        color: #b6b6b6;
        background: none;
        display: flex;
        padding: 10px;
        margin: 0 auto;
    }
}

.body-chooser-container {
    position: relative;
    z-index: 200;
    width: 100%;
    max-width: 540px;
}

.body-chooser-creative {
    position: relative;
    margin-bottom: 24px;
    img {
        object-fit: contain;
        width: 100%;
        border-radius: 32px;
    }
    button {
        position: absolute;
        z-index: 30;
        bottom: 0;
        right: 0;
        width: 56px;
        height: 56px;
        box-shadow: 0 0 25px 0 rgba(10,19,101, .4);
        background-color: #4d5ef6;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            width: 24px;
        }
    }
}

.btns-template-container {
    display: flex;
    overflow: auto;
    box-sizing: border-box;
    padding-bottom: 6px;    
    &::-webkit-scrollbar-track {
        border-radius: 8px;
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: #cdcdcd;
    }
    button {
        width: 54px;
        height: 54px;
        border-radius: 12px;
        margin-right: 8px;
        background-size: cover;
        flex-shrink: 0;
    }

    .btn-switch-to-female {
        background-image: url(/assets/images/bodychooser/female.png);
    }

    .btn-switch-to-male {
        background-image: url(/assets/images/bodychooser/male.png);
    }
}

.body-chooser-loader {
    position: relative;
    background-color: #e5e5e5;
    border-radius: 24px;
    overflow: hidden;
    z-index: 20;
    margin-bottom: 24px;
    &:before {
        content: '';
        display: block;
        padding-top: 100%;
    }
    .loader-roller-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}

@media all and (max-width: 2400px) {
    .main-page,
    .collage-page {
        padding-top: 16px;
        padding-bottom: 24px;
    }
    .main-page {
        padding-bottom: 0;
        &.love {
            height: 100vh;
            background-color: #fff;
            .wr-slider-images {
                position: relative;
                width: calc(100% + 40px);
                left: -20px;
            }
        }
        .btn-upload-foto {
            display: block;
            margin-top: 24px;
            margin-bottom: 0;
        }
    }    
    .header-main-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .header-other-content {
        display: none;
    }
    header {
        margin-bottom: 24px;
    }
    .logo {
        font-size: 20px;
    }
    .btn-photo-lab {
        font-size: 14px;
    }
    .main-page-content {
        margin-bottom: 56px;
        .wr-slider-images {
            margin-bottom: 0;
            .slide img {
                max-width: 460px;
                margin: 0 auto;
                display: block;
            }
            .slick-dots {
                bottom: 4px;
            }
        }
    }
    .main-page-subtitle {
        max-width: 100%;
    }
    .updated-at {
        font-size: 16px;
        text-align: center;
        border: dotted 2px #ebe5ff;
        border-radius: 16px;
        padding: 16px 25px;
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .main-page-title {
        font-size: 20px;
        line-height: 1.44;
        text-align: center;
        margin-bottom: 0;
    }
    .main-page-subtitle {
        font-size: 14px;
        text-align: center;
        margin-top: 8px;
        margin-bottom: 16px;
    }
    .wr-slider-images {
        margin-bottom: 16px;
    }
    .wr-slider-logos {
        width: calc(100% + 40px);
        position: relative;
        left: -20px;
        margin-bottom: 78px;
        a {
            width: 104px;
            margin: 0 auto;
        }
    }
    .posts-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 16px;
        a {
            border-radius: 12px;
            width: 32%;
            margin-bottom: 6px;
        }
    }

    .btns-market-container {
        flex-direction: column;
        margin-bottom: 40px;
        .btn-store {
            font-size: 16px;
            width: 200px;
            height: 48px;
            border-radius: 12px;
            padding-left: 32px;
            svg {
                height: 20px;
                width: 20px;
                fill: $color-white;
                margin-right: 8px;
            }
            &:last-child {
                margin-top: 8px;
            }
            svg {
                height: 20px;
                width: 20px;
                margin-right: 8px;
            }
        }
    }
    .instagram {
        margin-bottom: 34px;
        .user-info {
            display: none;
        }
        .post {
            margin-bottom: 6px;
        }
        .post-image {
            margin-bottom: 0;
        }
    }
    .title-contacts-form {
        font-size: 16px;
        text-align: center;
        padding-right: 0;
    }
    .btn-contacts-form-footer {
        display: block;
    }
    .btn-privacy {
        font-size: 16px;
    }
    .update-info {
        font-size: 20px;
        border-radius: 16px;
        text-align: center;
        padding: 16px;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 0;
        span {
            text-align: center;
        }
    }
}


@media all and (max-height: 700px) {
    .collage-page {
        .creative-holder {
            max-width: 320px;
            &.creative-holder--narrow {
                max-width: 240px;
            }
        }       
    }
    .collage-page header {
        margin-bottom: 5vh;
    }
}

@media all and (max-height: 550px) {
    .collage-page {
        overflow-y: auto;

        .creative-holder {
            &.creative-holder--narrow {
                max-width: 200px;
            }
        }
    }
}

@media all and (max-width: 650px) {
    .modal-container-body-select {
       .modal {
            padding: 42px 20px 52px;
        }
        .btns-container {
            flex-direction: column;
        }
        .btn-select-style {
            width: 100%;
            height: 60px;
            max-width: 360px;
            margin-top: 20px;
        }
    }

    .btns-template-container {
        position: relative;
        left: -20px;
        width: calc(100% + 40px);
        padding-left: 20px;
    }
}
