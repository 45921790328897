.btn {
    &-photo-lab {
        font-family: $font-secondary;
        font-size: 24px;
        line-height: 1;
        color: $color-secondary;
        text-transform: uppercase;
    }
    &-drop {
        font-family: $font-secondary;
        color: $color-white;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        width: 226px;
        height: 80px;
        border-radius: 20px;
        position: relative;
        background-color: $color-secondary;
        padding-left: 24px;
        &:hover {
            background-color: $color-hover;
        }
        span {
            margin-top: 3px;
        }
        svg {
            width: 24px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            transition-duration: .6s;
            right: 16px;
        } 
        &-store {
            display: flex;
            align-items: center;
            height: 80px;
            box-sizing: border-box;
            padding-left: 31px;
            &:first-child {
                border-bottom: 1px solid rgba(10, 19, 101, 0.2);
            }
            svg {
                width: 32px;
                height: 32px;
                fill: #fff;
                margin-right: 16px;
            }
            &-content {
                font-family: $font-secondary;
                color: $color-white;
                margin-top: 3px;
            }
            &:hover {
                background-color: $color-hover;
            }
        }
        &.active {
            svg {
                transform: translateY(-50%) rotate(90deg);
            }
        }
    }
    &-contacts-form {
        font-family: $font-secondary;
        text-transform: uppercase;
        color: #2d3134;
        display: flex;
        align-items: center;
        justify-content: center;
        &-header {
            color: $color-black;
            width: 226px;
            height: 80px;
        }
        &-footer {
            font-size: 16px;
            color: $color-secondary;
            display: none;
        }
    }
    &-privacy {
        font-family: $font-secondary;
        line-height: 1;
        color: $color-grey-dark;
    }
    &-upload-foto {
        font-family: $font-secondary;
        font-size: 14px;
        color: $color-white;
        text-transform: uppercase;
        width: 200px;
        height: 48px;
        border-radius: 16px;
        background-color: $color-secondary;
        display: block;
        margin: 0 auto 40px;
        &-desktop {
            position: absolute;
            bottom: 24px;
            left: -130px;
            width: 228px;
            height: 228px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            z-index: 20;
            .btn-icon {
                width: 160px;
                height: 160px;
                border-radius: 50%;
                box-shadow: 0 0 60px 0 rgba(10, 19, 101, 0.6);
                background-color: $color-secondary;
                position: relative;
                &:before,
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    background-color: $color-white;
                    border-radius: 5px;
                }
                &:after {
                    width: 48px;
                    height: 4px;
                }
                &:before {
                    width: 4px;
                    height: 48px;
                }
            }
            svg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    &-store {
        font-family: $font-secondary;
        color: $color-white;
        font-size: 24px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        width: 320px;
        height: 80px;
        border-radius: 20px;
        background-color: $color-primary;
        box-sizing: border-box;
        padding-left: 40px;
        margin: 0 20px;
        svg {
            height: 32px;
            width: 32px;
            fill: $color-white;
            margin-right: 16px;
        }
        &-content {
            margin-top: 2px;
        }
    }
    &-hashtag {
        font-family: $font-secondary;
        font-size: 14px;
        color: $color-white;
        text-transform: uppercase;
        width: 96px;
        height: 34px;
        border-radius: 6px;
        background-color: $color-green;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 8px auto 16px;
    }
    &-back {
        font-family: $font-secondary;
        font-size: 16px;
        color: $color-primary;
        text-transform: uppercase;
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 72px;
        display: flex;
        align-items: center;
        background-color: $color-white;
        box-shadow: 0 0 25px 0 rgba(10, 19, 101, 0.3);
        z-index: 20;
        padding-left: 24px;
        svg {
            width: 16px;
            height: 16px;
            margin-right: 8px;
        }
    }
    &-update {
        font-family: $font-secondary;
        font-size: 10px;
        line-height: 1.4;
        letter-spacing: 0.4px;
        color: $color-grey-dark;
        text-transform: uppercase;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 30%;
        width: 100%;
        position: relative;
        top: 20px;
        svg {
            width: 24px;
            height: 24px;
            margin-bottom: 8px;
        }
    }
}
