// -- privacy
// -- частично стянуто с https://toonme.app/privacy.html

.privacy__wrapper {
  width: 90%;
  max-width: 62rem;
  margin: 30px auto;

  p {
    text-align: left !important;
  }

  h1 {
    text-align: center;
    margin-bottom: 30px;
  }
}

.privacy__logo {
  font-family: 'Chewy';
  font-size: 30px;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1px;
  color: #fff;
  border-radius: 44px;
  background-color: #3fb3f9;
  display: inline-block;
  padding: 7px 17px;
}

.privacy__page_title {
  font-size: 22px;
  font-weight: bold;
  line-height: 1.33;
  color: #3fb3f9;
  margin-bottom: 33px;
}

.privacy__paragraph {
  width: 100%;
  font-size: 1rem;
  line-height: 150%;
  font-weight: 300;
  letter-spacing: .03rem;
  margin-bottom: 2rem !important;
}

.privacy__list {
  list-style: disc;
  line-height: 150%;
  margin: 0 0 2rem 2rem;
}

.privacy__paragraph span {
  display: block;
  text-transform: uppercase;
  margin-bottom: 0.4rem;
}

.privacy__paragraph a {
  color: #3fb3f9;
  text-decoration: underline
}

.privacy__title {
  font-weight: 700;
}

@media (max-width: 700px) {
  .policy__wrapper {
    font-size: 14px
  }
}

@media (max-width: 500px) {
  .policy__wrapper {
    font-size: 13px
  }
}

@media (max-width: 400px) {
  .policy__wrapper {
    font-size: 12px
  }
}

@media (max-width: 370px) {
  .policy__wrapper {
    font-size: 11px
  }
}

// ---

.collage__tab_disney5276 { background-image: url("/assets/img/tabs/disney5276.jpg?1"); }
.collage__tab_disney5277 { background-image: url("/assets/img/tabs/disney5277.jpg?1"); }
.collage__tab_barbie5278 { background-image: url("/assets/img/tabs/barbie5278.jpg?1"); }
.collage__tab_barbie5279 { background-image: url("/assets/img/tabs/barbie5279.jpg?1"); }
.collage__tab_komuk3111 { background-image: url("/assets/img/tabs/tab-icon-komuk3111.jpg?1"); }
.collage__tab_carlafuentesart { background-image: url("/assets/img/tabs/tab-icon-carlafuentesart.jpg?1"); }
.collage__tab_scarydolls { background-image: url("/assets/img/tabs/tab-icon-scarydolls.jpg?1"); }
.collage__tab_popart { background-image: url("/assets/img/tabs/tab-icon-popart.png?1"); }
.collage__tab_simpsons { background-image: url("/assets/img/tabs/tab-icon-1.jpg?1"); }
.collage__tab_disney_with_5273,
.collage__tab_disney { background-image: url("/assets/img/tabs/tab-icon-2.jpg?1"); }
.collage__tab_disney_2d { background-image: url("/assets/img/tabs/tab-icon-6.jpg?1"); }
.collage__tab_disney_collage { background-image: url("/assets/img/tabs/tab-icon-5266.jpg?1"); }
.collage__tab_archer { background-image: url("/assets/img/tabs/tab-icon-3.jpg?1"); }
.collage__tab_vector { background-image: url("/assets/img/tabs/tab-icon-4.jpg?1"); }
.collage__tab_collage { background-image: url("/assets/img/tabs/tab-icon-5.png?1"); }
.collage__tab_caricature { background-image: url("/assets/img/tabs/tab-icon-caricature.png?1"); }
.collage__tab_caricature_2 { background-image: url("/assets/img/tabs/tab-icon-caricature_2.jpg?1"); }
.collage__tab_caricature_3 { background-image: url("/assets/img/tabs/tab-icon-caricature_3.jpg?1"); }
.collage__tab_t4532 { background-image: url("/assets/img/tabs/tab-icon-10.png?1"); }
.collage__tab_barbie { background-image: url("/assets/img/tabs/tab-icon-barbie.jpg?1"); }
.collage__tab_cartoon,
.collage__tab_cartoon2 { background-image: url("/assets/img/tabs/tab-icon-cartoon.jpg?1"); }
.collage__tab_vectorpsp { background-image: url("/assets/img/tabs/tab-icon-vectorpsp.jpg?1"); }
.collage__tab_cartoon_anim { background-image: url("/assets/img/tabs/tab-icon-cartoon_anim.jpg?1"); }
.collage__tab_cartoon_vector_body { background-image: url("/assets/img/tabs/tab-icon-cartoon_vector_body.jpg"); }
.collage__tab_cupid21_1 { background-image: url("/assets/img/tabs/tab-icon-14feb21_4601.jpg"); }
.collage__tab_cupid21_2 { background-image: url("/assets/img/tabs/tab-icon-14feb21_4600.jpg"); }
.collage__tab_voila { background-image: url("/assets/img/tabs/voila.jpg?1"); }
.collage__tab_tab210622 { background-image: url("/assets/img/tabs/tab210622.jpg?1"); }
.collage__tab_tab210623 { background-image: url("/assets/img/tabs/tab210623.jpg?1"); }
.collage__tab_disney5336 { background-image: url("/assets/img/tabs/disney5336.jpg?1"); }
.collage__tab_disney2dmix { background-image: url("/assets/img/tabs/disney2dmix.jpg?1"); }
.collage__tab_disney5357 { background-image: url("/assets/img/tabs/disney5357.jpg?1"); }
.collage__tab_disney5361 { background-image: url("/assets/img/tabs/disney5361.jpg?1"); }
.collage__tab_disney5365 { background-image: url("/assets/img/tabs/disney5365.jpg?1"); }
.collage__tab_disney5366 { background-image: url("/assets/img/tabs/disney5366.jpg?1"); }
.collage__tab_disney5367 { background-image: url("/assets/img/tabs/disney5367.jpg?1"); }