.imageview-host {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-color: rgba(0, 0, 0, .1);

  .imageview-holder {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .imageview-holder,
  .imageview-image {
    animation: imageview-show 320ms;
  }
}

@keyframes imageview-show {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.footer-links {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 20px 0 20px;

  a {
    color: #999;
    text-decoration: underline;
  }
}

span.apptab-feed-icon {
  position: relative;
  top: 2px;
  display: inline-block;
  width: 18px;
  height: 20px;
  background: url("/assets/img/flame.svg") -4px -1px no-repeat;
}

.app-locale--de {
  .modal .btn-upload-foto {
    max-width: 135px;
  }
  .modal .btn-upload-foto.download {
    max-width: 155px;
  }
  .collage-page .btn-upload-foto {
    max-width: 220px;
  }
}

.app-locale--es {
  .modal .btn-upload-foto {
    max-width: 130px;
  }
  .modal .btn-upload-foto.download {
    max-width: 150px;
  }
}

.app-locale--fr {
  .modal .btn-upload-foto {
    max-width: 130px;
  }
  .modal .btn-upload-foto.download {
    max-width: 165px;
  }
  .modal.rate .btn-upload-foto {
    max-width: 200px;
  }
}

.app-locale--ru {
  .collage-page .btn-upload-foto {
    max-width: 235px;
  }
  .btn-refresh-foto {
    left: calc(50% + 150px);
  }
}
@media (max-width: 342px) {
  .app-locale--ru {
    .collage-page .btn-upload-foto {
      max-width: 226px;
    }
    .btn-refresh-foto {
      left: calc(50% + 134px);
    }
  }
}

.updated-at {
  color: #999;
  margin-top: 6px;
}

.warning-clones {
  margin: 0;
}

.warning-clones a{
  color: red;
  text-decoration: underline;
}
