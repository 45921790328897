@import "reset";
@import "fonts";
@import "common";

.app-design-v1 {
    @import "./v1/app_v1";
}
.app-design-v2 {
    @import "./v2/app_v2";
    &.app-locale {
        &--ru,
        &--de,
        &--fr,
        &--it {
            .btn-drop {
                font-size: 20px;
                span {
                    max-width: 80%;
                }
            }
        }
        &--ru {
            .main-page-title {
                font-family: inherit;
            }
            @media all and (max-width: 1380px) {
                .main-page {
                    .main-page-title {
                        font-size: 44px;
                    }
                }
            }
            @media all and (max-width: 900px) {
                .main-page {
                    .main-page-title {
                        font-size: 28px;
                    }
                }
            }
            @media all and (max-width: 880px) {
                .main-page-labs-info {
                    .main-page-title {
                        font-size: 24px;
                    }
                }
            }
            @media (max-width: 460px) {
                .app-design-v2 .main-page-content {
                    margin-bottom: 90px;
                }
            }
            @media all and (max-width: 380px) {
                .main-page {
                    .main-page-title {
                        font-size: 24px;
                    }
                }
            }
            @media all and (max-width: 330px) {
                .main-page {
                    .main-page-title {
                        font-size: 22px;
                    }
                }
            }
        }

        &--ja {
            .feed-page {
                button {
                    width: 270px;
                    svg {
                        margin-top: -2px;
                    }
                    span:first-of-type {
                        margin-top: 4px;
                    }
                }
            }
            @media all and (max-width: 410px) {
                .feed-page .subtitle {
                    margin-top: 0;
                }
               .feed-page h4 {
                    font-size: 16px;
                    line-height: 1.5;
                }
            }
            @media all and (max-width: 380px) {
                .feed-page .steps-list span {
                    font-size: 12px;
                }
            }
        }
    }

    .collage-page-content .btn-refresh-foto-desktop-v2 {
        display: none;
    }

    &.app-design-v2--result-v3 {
        .btn-refresh-foto-desktop-v1 {
            display: none;
        }
        .collage-page .btns-container {
            justify-content: space-around;
        }
        .btn-refresh-foto-desktop-v2 {
            display: flex;
            position: static;
            transform: none;
            flex-shrink: 0;
            font-size: 16px;
            svg {
                width: 25px;
                height: 24px;
                margin-right: 12px;
            }
            &[disabled] {
                pointer-events: none;
                opacity: .2;
            }
        }
        .btn-update.btn-update-big {
            position: static;
            transform: none;
            flex-shrink: 0;
            width: auto;
            span {
                font-size: 16px;
            }
            svg {
                width: 25px;
                height: 24px;
                margin-right: 12px;
            }
        }
        @media all and (max-width: 1200px) {
            .collage-page .btns-container {
                flex-direction: column;
            }
            .btn-update.btn-update-big,
            .btn-refresh-foto-desktop-v2 {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .btn-refresh-foto-desktop-v2 {
                margin-top: 20px;
            }
        }

        @media all and (max-width: 900px) {
            .btn-refresh-foto-desktop-v2 {
                display: none;
            }
            .collage-page .btns-container {
                flex-direction: row;
            }
        }
    }
}

.app-design-v3 {
    @import "./v3/app_v3";
    &.app-locale--ru {
        .main-page-title {
            font-size: 20px;
        }
    }
}
