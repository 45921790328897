@font-face {
    font-family: 'Chewy';
    src: url('../fonts/chewy-regular.woff2') format('woff2'),
         url('../fonts/chewy-regular.woff') format('woff');
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Regular.woff2') format('woff2'),
         url('../fonts/Poppins/Poppins-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('../fonts/Poppins/Poppins-SemiBold.woff2') format('woff2'),
         url('../fonts/Poppins/Poppins-SemiBold.woff') format('woff');
}

@font-face {
    font-family: 'Helvetica';
    font-weight: 400;
    src: url('../fonts/HelveticaRegular.woff2') format('woff2'),
         url('../fonts/HelveticaRegular.woff') format('woff');
}

@font-face {
    font-family: 'HelveticaBold';
    font-weight: 700;
    src: url('../fonts/HelveticaBold.woff2') format('woff2'),
         url('../fonts/HelveticaBold.woff') format('woff');
}