$color-primary: #2d3134;
$color-secondary: #4d5ef6;
$color-hover: #3244ea;
$color-white: #fff;
$color-black: #000;
$color-disabled: #999;
$color-green: #a4e6a7;
$color-red: #f6704d;
$color-grey: #e3e3e3;
$color-grey-dark: #b6b6b6;
$color-grey-middle: #ccc;
$color-pink: #f0679f;
$color-yellow: #ffce0a;

$bg-color-disabled: #f3f3f3;

$font-primary: 'Poppins', Arial, sans-serif;
$font-secondary: 'Poppins-SemiBold', Arial, sans-serif;
$font-logo: 'Chewy', sans-serif;
